const anime = require('animejs').default;
//const SVG = require('@svgdotjs/svg.js').SVG;

const screenWidthA = 500;
const screenWidthB = 1024;
const screenWidthC = 1280;

//import gspBackgroundPlayer from './lib/gspBackground.js';

export default () => {
  const movementMin = 10;
  //const main = document.querySelector('.main');
  const sections = document.querySelectorAll('section');
  const intro = document.querySelector('#intro');
  
  const sectionYs = [];
  const cityBg = document.querySelector('[data-section-bg="city"]');
  const observerTop = new IntersectionObserver(sectionWatcherTop, {rootMargin: '0% 0% -90% 0%'});
  const observerBtm = new IntersectionObserver(sectionWatcherBtm, {rootMargin: '-90% 0% 0% 0%'});
  
  let gspScrollActive = false;
  let w = window.innerWidth;
  let h = window.innerHeight;
  let pointerType = 'mouse';
  let previousSection = false;
  let nextSection = false;
  let activeSection = false;
  let activeSectionId = 0;
  let previousSectionId = 0;
  let nextSectionId =  1;
  let yPos = 0;
  let deltaY = 0;
  let incrementer = 0;
  let scrolling = false;
  let sectionLock = false;
  let sectionLockYStart = 0;
  let sectionLockYEnd = 0;

  let liveSection = false;

  window.addEventListener('setscrollposition', setScrollPosition);
  window.addEventListener('unlocksections', unlockSections);

  window.addEventListener('pointerdown', determinePointerType);

  window.addEventListener('sectionintro', sectionIntro);
  window.addEventListener('sectionoutro', sectionExit);

  window.addEventListener('nextsection', sectionExit);
  window.addEventListener('previoussection', sectionExit);

  window.addEventListener('movedownaction', nextScrollDownTransition);
  window.addEventListener('moveupaction', nextScrollUpTransition);
  window.addEventListener('disablestandardscroll', disableStandardScroll);
  window.addEventListener('enablestandardscroll', enableStandardScroll);
  window.addEventListener('gsptransitioncomplete', transitionComplete);
  window.addEventListener('gsptransitionsactive', transitionsActive);


  function sectionWatcherTop(entries) {
    for (var e=0; e< entries.length; e++) {
      const entry = entries[e];
      const section = entry.target;
      const name = section.getAttribute('id');
      const event = new CustomEvent('intersectiontrigger', {
        detail: { 
          section: section,
          boundary: 'top',
          entry: entry, 
        }
      });
      document.dispatchEvent(event);
    }
  }
  function sectionWatcherBtm(entries) {
    for (var e=0; e< entries.length; e++) {
      const entry = entries[e];
      const section = entry.target;
      const name = section.getAttribute('id');
      const event = new CustomEvent('intersectiontrigger', {
        detail: { 
          section: section,
          boundary: 'bottom',
          entry: entry, 
        }
      });
      document.dispatchEvent(event);
    }
  }

  function scrollCallback(e) {
    sections.forEach(section => {
      const state = section.getAttribute('data-section-state');
      const active = section.getAttribute('data-opacity');
      const visible = (state == 'visible') ? true : false;
      const id = section.getAttribute('id');
      if (active == 1) {
        const bounds = section.getBoundingClientRect();
        const y = bounds.y;
        const yPerc = y/h;
        let action = 'none';
        if (visible) {
          if (yPerc <= -.25 || y >= (h-100)) {
            //transition out
            action = 'outro';
          }
        } else {
          if (y <= 100 && yPerc >= -.25) {
            //transition in
            action = 'intro'
          } else {
            //transition out
            action = 'outro';
          }
        }

        switch (action) {
          case 'intro':
            section.setAttribute('data-section-state', action);
            const introEvent = new CustomEvent('sectionintro', {detail: { section: section }});
            break;
          case 'outro':
            section.setAttribute('data-section-state', action);
            const outroEvent = new CustomEvent('sectionoutro', {detail: { section: section }});
            break;
          default:
            // do nothing.
            //console.log('No action specified for: ' + action);
            break;
        }
      }
    });
  }

  function gspIn(e) {
    console.log('cityintro dispatch');
    //cityBg.setAttribute('data-opacity', 1);

    sectionExit(e);
  }
  function gspOut(e) {
    //cityBg.setAttribute('data-opacity', 0);
    sectionExit(e);
  }

  function sectionIntro(e) {
    activeSectionId = e.detail.section;
    //console.log(e);
    const section = document.querySelector('[data-section="'+ activeSectionId +'"]');
    const id = section.getAttribute('id');
    let event = false;
    //section.classList.add('enabled');
    switch (id) {
      case 'intro':
      break;
      case 'collections':
        //event = new CustomEvent('collectionsintro');
        //disableStandardScroll();
        //scrolling = false;
      break;
      case 'theartist':
        //event = new CustomEvent('artistintro');
      case 'exhibitions':
      case 'press':
      case 'merchandise':
      default: 
      break;
    }

    /*
    anime({
      targets: section,
      opacity: 1,
      duration: 500,
      easing: 'easeInOutQuad',
      complete: function(anim) {
        if (event) {
          document.dispatchEvent(event);
        }
      } 
    });
    */
  }

  function transitionsActive(e) {
    //scrolling = true;
  }

  function transitionComplete(e) {
    //scrolling = false;
  }
  /*
  function scrollCallback(e) {
    if (gspScrollActive) {
      incrementer = window.scrollY - yPos;
      const id = activeSection.getAttribute('data-section');
      const bounds = sectionYs[id].bounds;
      const eventdown = new CustomEvent('movedownaction');
      const eventup = new CustomEvent('moveupaction');
      //scrollWindow(bounds.y);
      if (incrementer > 15) {
        //window.dispatchEvent(eventdown);
      }

      if (incrementer < -15) {
        //window.dispatchEvent(eventup);
      }
    }
  }
  */
  function sectionExit(e) {
    //console.log('sectionExit: ' + e.type);
    //scrolling = true;
    const eventType = e.type;
    const section = document.querySelector('[data-section="'+ activeSectionId +'"]');
    const pName = section.getAttribute('id');
    const totalSections = sections.length;

    switch (eventType) {
      case 'gspintroout':
        nextSectionId = 1;
      break;
      case 'gspintroin':
        nextSectionId = 0;
      break;
      case 'nextsection':
        nextSectionId = (nextSectionId === 5) ? 1 : nextSectionId + 1;
      break;
      case 'previoussection':
        nextSectionId = (nextSectionId === 1) ? 5 : nextSectionId - 1;
      break;
      default:
      break;
    }

    //fadeSectionOut(section);
  }

  function fadeSectionOut(section) {
    const isActive = section.classList.contains('enabled');
    const intro = new CustomEvent('sectionintro', {detail: {section: nextSectionId}});
    if (isActive) {
      anime({
        targets: section,
        opacity: 0,
        duration: 500,
        easing: 'easeInOutQuad',
        complete: function(anim) {
          previousSectionId = activeSectionId;
          section.classList.remove('enabled');
          resetSectionStartPosition(section);
          window.dispatchEvent(intro);
        }
      });
    } else {
      window.dispatchEvent(intro);
    }
  }

  function resetSectionStartPosition(section) {
    const name = section.getAttribute('id');
    let event = false;
    switch (name) {
      case 'collections':
        event = new CustomEvent('collectionsreset');
      break;
      default: 
      break;
    }

    if (event) {
      document.dispatchEvent(event);
    }
  }

  function initializeGSPScroller() {
    document.body.setAttribute('data-gspscroller', 'off');
    if (sections && sections.length) {
      for(var a=0; a<sections.length; a++) {
        observerTop.observe(sections[a]);
        observerBtm.observe(sections[a]);
      }
    }
  }

  function nextScrollDownTransition(e) {
    if (!scrolling) {
      console.log('nextScrollDownTransition');
      scrolling = true;
      let id = activeSection.getAttribute('data-section');
      const name = activeSection.getAttribute('id');
      const outro = new CustomEvent('sectionoutro');
      previousSection = activeSection;
      id++;
      activeSection = sections[id];
      console.log(activeSection);
      switch (name) {
        case 'intro':
          window.dispatchEvent(outro);
        case 'collections':
        default:
            /*
          id++;
          var newY = sectionYs[id].bounds.y;
          activeSection = sections[id];
          console.log(newY);
          console.log(window.scrollY);
          scrollWindow(newY);
          */
        break;
      }
    }
  }
  function nextScrollUpTransition(e) {
    if (!scrolling) {
      console.log('nextScrollUpTransition');
    }
    const id = activeSection.getAttribute('data-section');
    const fixScroll = new CustomEvent('enablestandardscroll');
    if (gspScrollActive && id === 0) {
      window.dispatchEvent(fixScroll);
    }
  }
  function disableStandardScroll(e) {
    //document.body.setAttribute('data-gspscroller', 'on');
    if (!gspScrollActive) {
      //gspScrollActive = true;
      //resetScrollY();
      switch(pointerType) {
        case 'touch':
        //window.addEventListener('touchmove', dragEventCallback);
        break;
        case 'mouse':
        //window.addEventListener('wheel', wheelEventCallback);
        break;
        default: 
        break;
      }
    
      if (!activeSection) {
        let introSection = sections[0];
        let bounds = introSection.getBoundingClientRect();
      }
    }
  }

  function enableStandardScroll(e) {
    //document.body.setAttribute('data-gspscroller', 'off');
    //gspScrollActive = false;
  }

  function sectionScrollListenerCallback(e) {
    const activeSections = document.querySelectorAll('section.active');
    if (activeSections && activeSections.length) {
      activeSections.forEach(section => {
        const p = atPostion(section);

        if (sectionLock) {
          if (window.scrollY <= sectionLockYStart) {
            scrollWindow(sectionLockYStart);
          }
          if (window.scrollY >= sectionLockYEnd)
            scrollWindow(sectionLockYEnd);
        }

        if(!sectionLock) {
          if (p.rperc <= .25 && !section.classList.contains('visible')) {
            //section.classList.add('visible');
            //section.setAttribute('data-opacity', 1);
          }
          if (p.rperc > .25 && section.classList.contains('visible')) {
            //section.classList.remove('visible');
            //section.setAttribute('data-opacity', 0);
          }
          if (p.rspace <= 0 && section.classList.contains('visible')) {
            //section.classList.remove('visible');
            //section.setAttribute('data-opacity', 0);
          }
        }
      });
    }
  }

  function atPostion(element) {
    const rect = element.getBoundingClientRect();
    return {
      rperc: rect.y/h,
      rspace: h + rect.y,
      rheight: rect.height - rect.y,
    }
  }

  function determinePointerType(e) {

    pointerType = e.pointerType;
  }
  function setScrollPosition(e) {
    const section = (e.detail.section) ? e.detail.section : false;
    const behavior = (e.detail.behavior) ? e.detail.behavior : 'instant';
    let y = (e.detail.y) ? e.detail.y : false;
    if (section) {
      const rect = section.getBoundingClientRect();
      y = window.scrollY+rect.y;
    }

    if (y) {
      scrollWindow(y, behavior);
    }

    if (e.detail.locksections) {
      sectionLock = true;
      sectionLockYStart = window.scrollY;
      sectionLockYEnd = window.scrollY + (rect.height-h);
    }
  }
  function unlockSections(e) {
    sectionLock = false;
  }
  function resetScrollY() {
    yPos = 0;
  }
  function scrollWindow(y, b = 'instant') {
    window.scrollTo({
      top: y,
      behavior: 'instant',
    });

  }

  apos.util.onReady(initializeGSPScroller);
}