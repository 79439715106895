const anime = require('animejs').default;
const SVG = require('@svgdotjs/svg.js').SVG;

const screenWidthA = 500;
const screenWidthB = 1024;
const screenWidthC = 1280;

//import gspBackgroundPlayer from './lib/gspBackground.js';

export default () => {
  let w = window.innerWidth;
  let h = window.innerHeight;
  let pressAnimation;


  window.addEventListener('resize', resizeCallback);

  function resizeCallback(e) {
  }

  function sectionScrollPercentage(id) {
    const element = document.getElementById(id);
    if (!element) {
      console.error('Element not found');
      return null;
    }
    // Get the bounding rectangle of the element
    const rect = element.getBoundingClientRect();

    // Get the height of the viewport
    const viewportHeight = window.innerHeight;

    // Calculate the percentage
    // The top of the element is 0% when it's at the bottom of the viewport (rect.top = viewportHeight)
    // and 100% when it's at the top of the viewport (rect.top = 0)
    const percentage = (rect.top / rect.height > 0) ? 0 : Math.abs(rect.top / rect.height);

    // Clamping the percentage between 0% and 100%
    return Math.max(0, Math.min(1, percentage));
  }
  function hitTest(element, coords) {
    var response = false;
    if (element) {
      var bounds = element.getBoundingClientRect();
      var middle = [(bounds.left + bounds.right) / 2, (bounds.top + bounds.bottom) / 2];
      var size = [middle[0] - bounds.left, middle[1] - bounds.top];

      var dx = Math.abs(coords[0] - middle[0]);
      var dy = Math.abs(coords[1] - middle[1]);

      response = (dx / size[0] + dy / size[1] <= 1);
    }
    return response;
  }
  
  apos.util.widgetPlayers['gspPress'] = {
    selector: '[data-gsp-press]',
    player: function (el) {
      let topTrigger = false;
      let btmTrigger = false;
      let initialized = false;
      let activePress = false;
      let pressAnimation = false;
      // As we're about to shift content out of .columns, we need it to hide its overflow
      const section = el.closest('section');
      const sId = section.getAttribute('id');
      const sBounds = section.getBoundingClientRect();
      const pressIframe = el.querySelector('.press-content iframe');
      const pressContent = el.querySelector('.press-content');
      const title = el.querySelector('.section-title');
      const pressItems = el.querySelectorAll('figure.press__column__item a');
      const pressDisplay = el.querySelector('.press-bg-display');
      const columns = el.querySelector('.columns');
      const reverseCols = el.querySelectorAll('.column-reverse');
      const rStartY = -sBounds.height + h;
      const rEndY = sBounds.height - h;
      const backButton = el.querySelector('.display-back-btn');
      document.addEventListener('intersectiontrigger', intersectionTriggerCallback);

      function showBackButton(){
        const backButton = el.querySelector('.display-back-btn');
        if (backButton) {
          backButton.style.visibility = 'visible';
          backButton.style.opacity = '1';
        }
      }

      function hideBackButton(){
        const backButton = el.querySelector('.display-back-btn');
        if (backButton) {
          backButton.style.visibility = 'hidden';
          backButton.style.opacity = '0';
        }
      }

      function handleFigureClick(e) {
          let figure = e.target.closest('figure');
          if(figure){
            let anchor = figure.querySelector('a');
            let figImg = figure.querySelector('img');
            if (anchor.href){
              let linkType = anchor.className;
              let pressImg = el.querySelector('.press-img');
              let iframe = el.querySelector('.press-iframe');
              pressImg.style.display = 'none';
              iframe.style.display = 'block';
              iframe.src = anchor.href;
              iframe.scrolling = 'yes';
              pressDisplay.classList.remove('videoUrl');
              if(linkType === 'videoUrl'){
                pressDisplay.classList.add('videoUrl');
                iframe.scrolling = 'no';

              }
            } else {
              let pressImg = el.querySelector('.press-img');
              let iframe = el.querySelector('.press-iframe');
              pressDisplay.classList.remove('videoUrl');
  
              pressImg.style.display = 'block';
              iframe.style.display = 'none';
              pressImg.src = figImg.src;
            }   
          }
      }


      function handleFigureClickSmallScreen(e){
        let figure = e.target.closest('figure');
        if (figure){
          let web = figure.querySelector('.webUrl');
          let vid = figure.querySelector('.videoUrl');
          let imgSrc = figure.querySelector('img').getAttribute('src');
          if(vid){
            e.preventDefault();
            let source = vid.getAttribute('href');
            let mobileDisplay = el.querySelector('.press-mobile-display');
            mobileDisplay.className = "press-mobile-display active vid";
            let mobileIframe = mobileDisplay.querySelector('iframe');
            mobileIframe.setAttribute('src',source); 
            showBackButton();
          }
          if (!vid && !web){
            let mobileDisplay = el.querySelector('.press-mobile-display');
            mobileDisplay.className = "press-mobile-display active img";
            mobileDisplay.querySelector('iframe').setAttribute('src',''); 
            mobileDisplay.querySelector('img').setAttribute('src',imgSrc); 
            showBackButton();
          }

        }
        if(e.target.className === 'press-back-btn'){
          hideBackButton();
          let mobileDisplay = el.querySelector('.press-mobile-display');
          if (mobileDisplay) {mobileDisplay.classList.remove('active')};
        }
      }

      function removeEventSmallScreen(e){
        let mobileDisplay = el.querySelector('.press-mobile-display');
        mobileDisplay.className = "press-mobile-display";
        hideBackButton();
      }

      function intersectionTriggerCallback(e) {
        if (e.detail.section === section) {
          const entry = e.detail.entry;
          const boundary = e.detail.boundary;
          if (boundary === 'top') {
            topTrigger = entry.isIntersecting;
          }
          if (boundary === 'bottom') {
            btmTrigger = entry.isIntersecting;
          }

          if (!initialized) {
            initialized = true;
            if (w >= screenWidthB) {
              
            }
          }

          validateListeners();
          if (w >= screenWidthB) {
            if (topTrigger && btmTrigger) {
              introAnimation();
            } else {
              outroAnimation();
            }
          }

          //console.log('top: ' + topTrigger + ' - btm: ' + btmTrigger);
        }
      }

      function validateListeners() {
        let valid = (topTrigger || btmTrigger) ? true : false;

        if (!valid) {
            window.removeEventListener('scroll', scrollCallback);
            document.removeEventListener('click', sectionClick);
            document.removeEventListener('click', handleFigureClick);
            document.removeEventListener('click', handleFigureClickSmallScreen);
        }
        
        if (w >= screenWidthB && valid) {
          window.addEventListener('scroll', scrollCallback);
          document.addEventListener('click', sectionClick);
          pressItems.forEach(item => {
            item.addEventListener('click', function(e) {
                e.preventDefault();
            })
          });
          document.addEventListener('click', handleFigureClick);

        }

        if (w < screenWidthB) {
          document.addEventListener('click', handleFigureClickSmallScreen);
          // window.addEventListener('scroll', removeEventSmallScreen);
        }
      }

    
      function sectionClick(e) {
        const coords = [e.clientX, e.clientY];
        let hit = false;
        /*
        pressItems.forEach(item => {
            const a = item.querySelector('a');
            const href = a.getAttribute('href');
            console.log(href);
            hit = (hitTest(item, coords)) ? true : false;
            if (hit) {
              activePress = item;
              item.classList.add('active');
              //showPress(href);
            }
          });
*/
        if (activePress) {
            //hideseries();
        }
      }

      function showPress(href) {
        const bounds = pressDisplay.getBoundingClientRect();
        if (pressIframe) {
          href = (href) ? href : pressIframe.getAttribute('src');
        }

        // anime({
        //   targets: pressDisplay,
        //   translateX: bounds.x,
        //   opacity: 0,
        //   duration: 500,
        //   easing: 'easeInOutSine',
        //   complete: function(anim) {
        //     if (pressIframe) {
        //       pressIframe.setAttribute('src', href);
        //     }
            // anime({
            //   targets: pressDisplay,
            //   translateX: 0,
            //   opacity: 1,
            //   duration: 500,
            //   easing: 'easeInOutSine',
            // });
            // anime({
            //   targets: pressDisplay,  
            //   translateX: 0,  
            //   opacity: 1,  
            //   easing: 'easeInOutSine',
            //   duration: 1000  
            // });
          }
        // });

        
      // }


      function introAnimation() {
        // showPress();
        anime({
          targets: title,
          translateX: {value: 0, duration: 1000},
          opacity: {value: 1, duration: 1500},
          easing: 'easeInOutSine',
        });
        // anime({
        //   targets: pressDisplay,
        //   translateX: {value: 0, duration: 1000},
        //   opacity: {value: 1, duration: 1500},
        //   easing: 'easeInOutSine',
        // });
      }
      function outroAnimation() {
        const titleBounds = title.getBoundingClientRect();
        anime({
          targets: title,
          translateX: {value: titleBounds.width, duration: 1000},
          opacity: {value: 0, duration: 500},
          easing: 'easeInOutSine',
          duration: 1000
        });
        // anime({
        //   targets: pressDisplay,
        //   translateX: {value: titleBounds.width, duration: 1000},
        //   opacity: {value: 0, duration: 500},
        //   easing: 'easeInOutSine',
        //   duration: 1000
        // });
      }

      function addAnimations() {
        if (!pressAnimation) {
            //transform: translateY(calc(-100% + 100svh));
            /*
          const sy = -sBounds.height + h;
          const ey = sBounds.height;
          console.log(reverseCols);
          pressAnimation = anime({
            targets: reverseCols,
            translateY: [sy, ey],
            duration: sBounds.height,
            easing: 'easeInOutSine',
            autoplay: false
          });
          */
        }
      }

      function scrollCallback(e) {
        const perc = sectionScrollPercentage(sId)

        if (pressAnimation && perc > 0) {
          //console.log(perc * pressAnimation.duration)
          //pressAnimation.seek(perc * pressAnimation.duration);
        }
      }



      columns.style.overflowY = "hidden";


      
    }
  }
}