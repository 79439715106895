const anime = require('animejs').default;
const SVG = require('@svgdotjs/svg.js').SVG;

const screenWidthA = 500;
const screenWidthB = 1024;
const screenWidthC = 1280;

//import gspBackgroundPlayer from './lib/gspBackground.js';

export default () => {
  let w = window.innerWidth;
  let h = window.innerHeight;

  apos.util.widgetPlayers['gspMerch'] = {
    selector: '[data-gsp-merch]',
    player: function (el) {
      let topTrigger = false;
      let btmTrigger = false;

      const section = el.closest('section');
      const image = el.querySelector('.merch-image');

      document.addEventListener('intersectiontrigger', intersectionTriggerCallback);

      function intersectionTriggerCallback(e) {
        if (e.detail.section === section) {
          const entry = e.detail.entry;
          const boundary = e.detail.boundary;
          if (boundary === 'top') {
            topTrigger = entry.isIntersecting;
          }
          if (boundary === 'bottom') {
            btmTrigger = entry.isIntersecting;
          }
    
          validateListeners();
    
          if (topTrigger && btmTrigger) {
            sectionIntro();
          } else {
            sectionOutro();
          }
        }
      }

      function validateListeners() {
        if (topTrigger && btmTrigger) {
          const share = document.querySelector('.floating-btn');
          share.click();
        }
      }
      function sectionIntro() {
        //console.log('intro');
        anime({
          targets: image,
          translateX: {value: 0, duration: 1500},
          opacity: {value: 1, duration: 500, delay: 1000},
          easing: 'easeInOutSine'
        });
      }
      function sectionOutro() {
        //console.log('outro');
        const bounds = image.getBoundingClientRect();
        anime({
          targets: image,
          translateX: {value: bounds.width, duration: 1000, delay: 500},
          opacity: {value: 0, duration: 500},
          easing: 'easeInOutSine'
        });
      }
    }
  }
}
