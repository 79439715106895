const anime = require('animejs').default;
const SVG = require('@svgdotjs/svg.js').SVG;

const screenWidthA = 500;
const screenWidthB = 1024;
const screenWidthC = 1280;

//import gspBackgroundPlayer from './lib/gspBackground.js';

export default () => {
  apos.util.widgetPlayers['gspArtist'] = {
    selector: '[data-gsp-artist-info]',
    player: function (el) {
      let w = window.innerWidth;
      let h = window.innerHeight;
      let topTrigger = false;
      let btmTrigger = false;
      const section = el.closest('section');
      const imgContainer = el.querySelector('.artist-image');
      const flagContainer = el.querySelector('.artist-flag');
      const imgs = imgContainer.querySelectorAll('img');
      const title = el.querySelector('.section-title');
      const contentContainer = el.querySelector('.artist-info-content');
      const observer = new IntersectionObserver(scrollWatcher, { threshold: [.2, .4, .6, .8, 1] })
      window.addEventListener('resize', resizeCallback);
      document.addEventListener('intersectiontrigger', intersectionTriggerCallback);
      function intersectionTriggerCallback(e) {
        if (e.detail.section === section) {
          const entry = e.detail.entry;
          const boundary = e.detail.boundary;
          if (boundary === 'top') {
            topTrigger = entry.isIntersecting;
          }
          if (boundary === 'bottom') {
            btmTrigger = entry.isIntersecting;
          }

          validateListeners();
          if (topTrigger && btmTrigger) {
            showImages();
          } else {
            hideImages();
          }

          //console.log('top: ' + topTrigger + ' - btm: ' + btmTrigger);
        }
      }

      function validateListeners() {
        let valid = (topTrigger && btmTrigger) ? true : false;
        
        if (w > screenWidthA) {
          valid = (btmTrigger) ? true : false;
        }

        if (valid) {
          //showImages();
        }

        if (!valid) {
          //hideImages();
        }
      }

      function resizeCallback(e) {
        w = window.innerWidth;
        h = window.innerHeight;
      }

      function showImages() {
        flagContainer.classList.add('visible');
        flagContainer.querySelector('img').setAttribute('data-opacity', 1);
        imgContainer.classList.add('visible');
        imgContainer.querySelector('img').setAttribute('data-opacity', 1);
        if (w > screenWidthB) {
          anime({
            targets: title,
            translateX: {value: 0, duration: 1000},
            opacity: {value: 1, duration: 1500},
            easing: 'easeInOutSine',
          });
        }
      }
      function hideImages() {
        const titleBounds = title.getBoundingClientRect();
        flagContainer.classList.remove('visible');
        flagContainer.querySelector('img').setAttribute('data-opacity', 0);
        imgContainer.classList.remove('visible');
        imgContainer.querySelector('img').setAttribute('data-opacity', 0);
        if (w > screenWidthB) {
          anime({
            targets: title,
            translateX: {value: titleBounds.width, duration: 1000},
            opacity: {value: 0, duration: 500},
            easing: 'easeInOutSine',
            duration: 1000
          });
        }
      }
      
      //document.addEventListener('artistintro', artistIntro);

      // add this to all section players;
      //document.addEventListener('sectionintro', sectionIntro);
      //document.addEventListener('sectionoutro', sectionOutro);
      function sectionIntro(e) {
        const target = e.detail.section;
        if (target === section) {
          sectionAnimationIn();
        }
      }
      function sectionOutro(e) {
        const target = e.detail.section;
        if (target === section) {
          sectionAnimationOut();
        }
      }
      function sectionAnimationIn() {
        console.log('intro');
      }
      function sectionAnimationOut() {
        console.log('outro');
      }
      // end all section players intro/outros

      function artistIntro(e) {
        //imgs[0].setAttribute('data-opacity', 1);
        
      }
      function scrollWatcher(entries) {
        console.log(entries);
      }
    }
  }
}