const anime = require('animejs').default;
const SVG = require('@svgdotjs/svg.js').SVG;
//const getPixels = require("get-pixels");
import { extractColors } from 'extract-colors';

const screenWidthA = 501;
const screenWidthB = 1024;
const screenWidthC = 1280;

export default () => {
  let primaryColor = false;
  let w = window.innerWidth;
  let h = window.innerHeight;
  let aspectRatio = h/w;
  let touch = (w < screenWidthB) ? true : false;

  window.addEventListener('touchstart', initializeTouch);
  window.addEventListener("resize", windowResize);

  function windowResize(e) {
    w = window.innerWidth;
    h = window.innerHeight;
  }

  function hitTest(element, coords) {
    var response = false;
    if (element) {
      var bounds = element.getBoundingClientRect();
      var middle = [(bounds.left + bounds.right) / 2, (bounds.top + bounds.bottom) / 2];
      var size = [middle[0] - bounds.left, middle[1] - bounds.top];

      var dx = Math.abs(coords[0] - middle[0]);
      var dy = Math.abs(coords[1] - middle[1]);

      response = (dx / size[0] + dy / size[1] <= 1);
    }
    return response;
  }
  
  async function setImgColor(src, hex = false) {
    let color = false;
    let colors = false;
    
    if (hex) {
      color = hex;
    } else {
      const options = {
        pixels: 64000,
        distance: 0.22,
        colorValidator: (red, green, blue, alpha = 255) => alpha > 250,
        saturationDistance: 0.2,
        lightnessDistance: 0.2,
        hueDistance: 0.083333333
      }
      colors = await extractColors(src, options).catch(console.error);
    }
    
    const event = new CustomEvent('updatecolors', {
      detail: {
        color: color,
        colors: colors,
      }
    });
    
    window.dispatchEvent(event);
  }

  function initializeTouch(e) {
    touch = true;
    window.removeEventListener('touchstart', initializeTouch);
  }


  apos.util.widgetPlayers['collectionMenu'] = {
    selector: '[data-collection-menu]',
    player: function (el) {
      let topTrigger = false;
      let btmTrigger = false;
      let listenersActive = false;
      let seriesAnimating = false;
      let sectionActive = false;
      const hbb = document.querySelector('.hbb');
      const section = el.closest('section');
      const totalCollections = el.getAttribute('data-total-collections');
      const menus = el.querySelectorAll('.collection-menu-item');
      const menuContainer = el.querySelector('.collection-menu-container');
      const titleContainer = el.querySelector('.collection-titles');
      const seriesContainer = el.querySelector('.series-display');
      const intersectY = .75;
      const btnSize = 48;
      const mobileBackButton = section.querySelector('.collection-back-btn');
      // let thumbnailItems = el.querySelectorAll('.collection-thumbnail');
      // let thumbnailList = el.querySelector('.artwork-thumbnail');
      // const collectionDesc = el.querySelector('.collection-desc');
      const navBar = document.querySelector('.container');
      const footer = document.querySelector('.gsp-footer');
      const infoPanel = document.querySelector('.info-panel');

      // function positionDescription(id){

      // let navHeight = navBar ? navBar.offsetHeight : null;
      // let footerHeight = footer ? footer.offsetHeight : null;
      // let infoPanelHeight = infoPanel ? infoPanel.offsetHeight : null;
      // let collectionDescHeight = collectionDesc ? collectionDesc.offsetHeight : null;
      // let colDesPosition = (window.innerHeight - navHeight - infoPanelHeight - 56 - collectionDescHeight) / 2 + navHeight + 'px';
      // let thumbnailHeight = thumbnailList ? thumbnailList.offsetHeight : null;
      // console.log('thumbnailheight', thumbnailHeight);
      // let thumbnailPosition = (window.innerHeight - navHeight - infoPanelHeight - 56 - thumbnailHeight) / 2 + navHeight + 'px';
      // if (collectionDesc) {collectionDesc.style.top = colDesPosition};
      // if (thumbnailList) {thumbnailList.style.top = thumbnailPosition};
      // }

      
      let activeSeries = false;
      let screen = (w > screenWidthA) ? 'tablet' : 'phone'; 
      screen = (w > screenWidthC) ? 'desktop' : screen;
      let bgYBase = (screen === 'desktop') ? 90 : 64;
      let bgYBaseAdd = (screen === 'desktop') ? 24 : 16;
      const m = (screen === 'desktop') ? 2 : 1.5;

      let imgColor = false;
      let collectionTitleStr = 'Bring Us Your Flowers';

      document.addEventListener('intersectiontrigger', intersectionTriggerCallback);

      // thumbnailItems.forEach(item => item.addEventListener('click', handleThumbnailClicked));

      let imgObserver = new IntersectionObserver(imageWatcher, {rootMargin: '-45% 0% -45% 0%'});
      sizeMenu();

      function intersectionTriggerCallback(e) {
        if (e.detail.section === section) {
          const entry = e.detail.entry;
          const boundary = e.detail.boundary;
          if (boundary === 'top') {
            topTrigger = entry.isIntersecting;
          }
          if (boundary === 'bottom') {
            btmTrigger = entry.isIntersecting;
          }

          validateListeners();

          if (screen !== 'phone') {
            if (topTrigger && btmTrigger) {
              sectionIntro();
            } else {
              console.log(screen);
              sectionOutro();
            }
          }
        }
      }

      function validateListeners() {
        let valid = (topTrigger) ? true : false;
        valid = (btmTrigger) ? true : valid;

        if (valid && !listenersActive) {
          enableListeners();
        }

        if (!valid && listenersActive) {
          disableListeners();
        }
      }

      function enableListeners() {
        listenersActive = true;
        document.addEventListener('click', sectionClick);
      }
      function disableListeners() {
        document.removeEventListener('click', sectionClick);
        listenersActive = false;
      }

      function sectionClick(e) {
        const coords = [e.clientX, e.clientY];
        let hit = false;
        if (!activeSeries) {
          menus.forEach(menu => {
            const id = menu.getAttribute('data-collection-id');
            const title = titleContainer.querySelector('[data-collection-id="'+id+'"] span.highlight');
            hit = (hitTest(menu, coords)) ? true : hitTest(title, coords);
            if (hit) {
              activeSeries = seriesContainer.querySelector('[data-collection-id="'+ id +'"');
              menu.classList.add('active');
              showSeries(id);
              // positionDescription();
            }
          });
        }

        if (activeSeries) {
          if (mobileBackButton.getAttribute('data-opacity') === '1') {
            if (hitTest(mobileBackButton, coords)) {
              hideSeries();
            }
          }
        }
      }

      function sectionIntro() {
        if (!sectionActive) {
          sectionActive = true;
          
          anime({
            targets: menus,
            opacity: 1,
            //left: 0,
            duration: 500,
            delay: anime.stagger(200, {grid: [1, 6], from: 'center'}),
            easing: 'easeInOutQuad',
          });
          
        }
      }
      function sectionOutro() {
        if (sectionActive) {
          sectionActive = false;
          
          anime({
            targets: menus,
            opacity: 0,
            duration: 250,
            delay: anime.stagger(200, {grid: [1, 6], from: 'center'}),
            easing: 'easeInOutQuad',
            complete: function(anim) {
            }
          });
          
        }
      }

      function sizeMenu() {
        //const headerBounds = hbb.getClientRects();
        if (w > screenWidthA) {
          const menuItemSize = w/totalCollections;
          anime({
            targets: menus,
            width: menuItemSize,
            duration: 500,
            easing: 'easeInOutQuad',
          });
        }
        if (w <= screenWidthA) {
          //const yPos = (bgYBase * m) + bgYBaseAdd;
        }
      }

      function hideSeries() {
        imgObserver.disconnect();
        document.removeEventListener('scroll', artworkScrollEvent);
        let event = new CustomEvent('showflowers');
        document.dispatchEvent(event);
        event = new CustomEvent('cityintro');
        document.dispatchEvent(event);

        const activeMenu = menuContainer.querySelector('.active');
        const id = activeSeries.getAttribute('data-collection-id');
        const cmenu = el.querySelector('.cmenu');
        const title = titleContainer.querySelector('[data-collection-id="'+ id +'"]');
        const scroll = new CustomEvent('setscrollposition', {detail: {section: el, behavior: 'smooth'}});
        window.dispatchEvent(scroll);
        
        // collectionDesc.classList.remove('active');
        // thumbnailList.classList.remove('active');
        let activeDesc = seriesContainer.querySelector('.collectionDesc[data-collection-id="'+ id +'"]');
        let collectionDesc = activeDesc.querySelector('.collection-desc');
        let thumbnailList = activeDesc.querySelector('.artwork-thumbnail');
        collectionDesc ? collectionDesc.classList.remove('active') : null;
        thumbnailList ? thumbnailList.classList.remove('active') : null;

        seriesContainer.querySelector('.info-panel').classList.remove('active');
        titleContainer.classList.remove('active-series');
        title.parentElement.classList.remove('visible');
        activeMenu.classList.remove('active');
        activeSeries.classList.remove('active');
        activeSeries.removeAttribute('style');
        //console.log(activeSeries.style);
        mobileBackButton.setAttribute('data-opacity', 0);
        setImgColor(false, '#fff');
        activeSeries = false;
      }

      function showSeries(id) {
        let event = new CustomEvent('hideflowers');
        document.dispatchEvent(event);
        event = new CustomEvent('cityoutro');
        document.dispatchEvent(event);

        const title = titleContainer.querySelector('[data-collection-id="'+ id +'"]');
        const infoPanel = seriesContainer.querySelector('.info-panel');
        const h5 = infoPanel.querySelector('h5');
        seriesContainer.querySelector('.info-panel').classList.add('active');
        titleContainer.classList.add('active-series');
        title.parentElement.classList.add('visible');


        activeSeries = seriesContainer.querySelector('.series-list[data-collection-id="'+ id +'"]');
        const seriesTitle = activeSeries.querySelector('h3');
        h5.innerHTML = seriesTitle.innerHTML;
        activeSeries.classList.add('active');

        let activeDesc = seriesContainer.querySelector('.collectionDesc[data-collection-id="'+ id +'"]');
        let collectionDesc = activeDesc.querySelector('.collection-desc');
        let thumbnailList = activeDesc.querySelector('.artwork-thumbnail');
        let thumbnailItems = activeDesc.querySelectorAll('.collection-thumbnail');
        thumbnailItems.forEach(item => item.addEventListener('click', handleThumbnailClicked));


        collectionDesc ? collectionDesc.classList.add('active') : null;
        thumbnailList ? thumbnailList.classList.add('active') : null;
        const scroll = new CustomEvent('setscrollposition', {detail: {section: activeSeries}});
        window.dispatchEvent(scroll);

        const sImages = activeSeries.querySelectorAll('img');
        sImages.forEach(image => {
          imgObserver.observe(image);
        });

        if (mobileBackButton.getAttribute('data-opacity') == '0') {
          if (w >= screenWidthB) {

            const border = document.querySelector('[data-gsp-header]').getBoundingClientRect();
            const bounds = mobileBackButton.getBoundingClientRect();
            const y = border.height - (bounds.height*.5);
            //mobileBackButton.style.top = y + 'px';
          }
          
          mobileBackButton.setAttribute('data-opacity', 1);
        }

        document.addEventListener('scroll', artworkScrollEvent);

        let navHeight = navBar ? navBar.offsetHeight : null;
        let footerHeight = footer ? footer.offsetHeight : null;
        let infoPanelHeight = infoPanel ? infoPanel.offsetHeight : null;
        let collectionDescHeight = collectionDesc ? collectionDesc.offsetHeight : null;
        let colDesPosition = (window.innerHeight - navHeight - infoPanelHeight - 56 - collectionDescHeight) / 2 + navHeight + 'px';
        let thumbnailHeight = thumbnailList ? thumbnailList.offsetHeight : null;
        console.log('thumbnailheight', thumbnailHeight);
        let thumbnailPosition = (window.innerHeight - navHeight - infoPanelHeight - 56 - thumbnailHeight) / 2 + navHeight + 'px';
        if (collectionDesc) {collectionDesc.style.top = colDesPosition};
        if (thumbnailList) {thumbnailList.style.top = thumbnailPosition};

      }

      function imageWatcher(entries) {
        console.log('called');
        console.log(entries.length);
        if (entries.length) {
          let entry = entries[0];
          console.log(entries);
          //entries.forEach(entry => {
            entries.forEach(item => {
              if (item.isIntersecting) {
                entry = item;
              }
            })
            if (entry.isIntersecting) {
              console.log(entries[0]);
              mobileBackButton.setAttribute('data-opacity', 1);
              var src = entry.target.getAttribute('src');
              //setImgColor(src);
              
              const infoCard = seriesContainer.querySelector('.info-panel .card-content');
              const children = infoCard.querySelectorAll('span');
              const series = entry.target.getAttribute('data-series');
              const sold = entry.target.getAttribute('data-sold');
              const title = entry.target.getAttribute('title');
              const desc = entry.target.getAttribute('data-description');

              anime({
                targets: children,
                opacity: 0,
                duration: 1000,
                easing: 'easeInOutQuad',
                complete: function(anim) {
                  children[0].innerHTML = title;
                  children[1].innerHTML = desc;
                  if (sold == 'true') {
                    children[0].classList.add('sold');
                  } else {
                    children[0].classList.remove('sold');
                  }
                  anime({
                    targets: children,
                    opacity: 1,
                    duration: 1000,
                    easing: 'easeInOutQuad'
                  });
                }
              });
            }
          //});
        }
      }

      function artworkScrollEvent(e) {
        let event;
        if (activeSeries) {
          const bounds = seriesContainer.getBoundingClientRect();
          const btn = mobileBackButton.getAttribute('data-opacity');
          if (bounds.top > 100 && !seriesAnimating) {
            //seriesAnimating = true;
            const scroll = new CustomEvent('setscrollposition', {detail: {section: el, behavior: 'smooth'}});
            window.dispatchEvent(scroll);
            hideSeries();
          }
          if (bounds.bottom <= 800 && btn == '1') {
            mobileBackButton.setAttribute('data-opacity', 0);
            seriesContainer.querySelector('.info-panel').classList.remove('active');
            // collectionDesc.classList.remove('active');
            // thumbnailList.classList.remove('active');
            event = new CustomEvent('showflowers');
            document.dispatchEvent(event);
            event = new CustomEvent('cityintro');
            document.dispatchEvent(event);
          }

          if (bounds.bottom > 800 && btn == "0") {
            mobileBackButton.setAttribute('data-opacity', 1);
            seriesContainer.querySelector('.info-panel').classList.add('active');
            // collectionDesc.classList.add('active');
            // thumbnailList.classList.add('active');

            event = new CustomEvent('hideflowers');
            document.dispatchEvent(event);
            event = new CustomEvent('cityoutro');
            document.dispatchEvent(event);
          }
        }
      }

      function menuHoverAction(e) {
        const coords = [e.clientX, e.clientY];
        menus.forEach(menu => {
          const hover = menu.classList.contains('hover');
          const id = menu.getAttribute('data-collection-id');
          const title = titleContainer.querySelector('[data-collection-id="'+id+'"]');
          const hit = hitTest(menu, coords);
          if (hit && !hover) {
            menu.classList.add('hover');
            title.classList.add('hover');

            var src = menu.querySelector('img').getAttribute('src');
            setImgColor(src);
            const expandedSize = (w/totalCollections)*3;
            anime({
              targets: menu,
              width: expandedSize,
              x: 0,
              duration: 500,
              easing: 'easeInOutQuad'
            });
          }

          if (!hit && hover) {
            menu.classList.remove('hover');
            title.classList.remove('hover');
            const collapsedSize = w/totalCollections;
            anime({
              targets: menu,
              width: collapsedSize,
              x: 0,
              duration: 500,
              easing: 'easeInOutQuad'
            });
          }
        });
      }

      function handleThumbnailClicked(e){
        let thumbnailLocation = e.target.getAttribute('thumbnail-location');
        location.href = '#' + thumbnailLocation;
      }
    }
  },
  apos.util.widgetPlayers['collections'] = {
    selector: '[data-all-collections]',
    player: function (el) {

    }
  }
}