const anime = require('animejs').default;
const SVG = require('@svgdotjs/svg.js').SVG;

const screenWidthA = 500;
const screenWidthB = 1024;
const screenWidthC = 1280;

//import gspBackgroundPlayer from './lib/gspBackground.js';

export default () => {
  let w = window.innerWidth;
  let h = window.innerHeight;

  let btmY = 0;
  let initialized = false;
  let ready = (window.scrollY < window.outerHeight)? true : false;
  let animations = false;
  let activeSection = false;
  let bottomSection = false;
  let topSection = false;
  const sections = {};
  const minDocWidth = screenWidthB;
  const minDocHeight = screenWidthA;
  const minAspect = .65;

  let minimumWidth = (w >= minDocWidth) ? true : false;
  let minimumHeight = (h >= minDocHeight) ? true : false;
  let minimumAspect = (h/w > minAspect) ? true : false;

  let cityActive = false;
  let visibility = testVisibility();
  let borderHeight;

  const city0 = document.querySelector('[data-gspcity-front]');
  const trees = document.querySelector('[data-gspcity-trees]');
  const statue = document.querySelector('[data-gspcity-statue]');
  const buildings = document.querySelectorAll('[data-gspcity-buildings]');
  const cityIntro = new CustomEvent('cityintro', { detail: { complete: true, }});
  const cityOutro = new CustomEvent('cityoutro', { detail: { complete: true, }});
  const xTarget = {};

  let cityAnimation;

  let headerAnimation, logoAnimation, city0Animation, treeAnimation, statueAnimation;

  window.addEventListener('resize', resizeWindow);
  //window.addEventListener('scroll', scrollCallback);
  //window.addEventListener('gspintroout', triggerIntro);
  //window.addEventListener('gspintroin', triggerOutro);

  document.addEventListener('intersectiontrigger', intersectionTriggerCallback);

  function addTransitions(sid, ha = 0) {
    const sectionHeight = getSectionHeight(sid);
    let bounds = statue.getBoundingClientRect();
    let eS = 1.8;
    let eX = - (bounds.left + ((bounds.width*eS)*.75));
    let eY = 0;
    let d = (sectionHeight * .5);
    let a = 0;


    switch (sid) {
      case 'intro':
        d = sectionHeight * .5;
      break;
      case 'collections':
        eS = .8;
        eX = (w*.5) - (bounds.width*eS);
        eY = (bounds.height*eS) * .1;
      break;
      case 'theartist':
      eS = .8;
      eX = 0;
      eY = 0;
      break;
      case 'exhibitions':
        eS = 1.2;
        eX = (w*.5) - ((bounds.width*.5)*eS);
        eY = 0;
      break;
      case 'press':
        eS = 1;
        eX = -(bounds.width*3);
        eY = 0;
        //eY = - (bounds.height * .1);
      break;
      default:
      break;
    }

    cityAnimation.add({
      targets: statue,
      translateX: eX,
      scale: eS,
      translateY: eY,
      duration: d - a,
    }, d + a + ha);

    if (buildings && buildings.length) {
      a = 250;
      buildings.forEach(building => {
        bounds = building.getBoundingClientRect();
        switch (sid) {
          case 'intro':
            eS = 1.2;
            eX = -w;
            //eX = - (bounds.left + ((bounds.width*eS)*.3));
          break;
          case 'collections':
            eS = .5;
            eX = w - bounds.width;
            eY = bounds.height * .3;
          break;
          case 'theartist':
            eS = .5;
            eX = (bounds.left + ((bounds.width*eS)*.3));
            eY = bounds.height * .3;
          break;
          case 'exhibitions':
            eS = .5;
            eX = - (bounds.left + ((bounds.width*eS)*.3) + 100);
            eY = bounds.height * .3;
          break;
          case 'press':
            eS = .5;
            eX = w - bounds.width;
            eY = bounds.height * .05;
          break;
          default:
          break;
        }

        cityAnimation.add({
          targets: building,
          translateX: eX,
          scale: eS,
          translateY: eY,
          duration: d - a,
        }, d + a + ha);
        a += 10;
      });
    }
  }

  function intersectionTriggerCallback(e) {
    const s = e.detail.section;
    const sId = s.getAttribute('id');
    const boundary = e.detail.boundary;
    const entry = e.detail.entry;

    if (!sections[sId]) {
      sections[sId] = {
        section: s,
        boundaries: {
          top: false,
          bottom: false
        }
      }
    }

    sections[sId].boundaries[boundary] = entry.isIntersecting;

    if (!initialized) {
      initialized = true;
      if (w >= screenWidthB) {
        addAnimations();
        window.addEventListener('scroll', scrollCallback);
      }
    }

    if (initialized) {
      const sTriggers = sections[sId].boundaries;
      if (sTriggers.top && sTriggers.bottom) {
        activeSection = s;
      }

      if (w > screenWidthB) {
        if (sTriggers.top || sTriggers.bottom && entry.isIntersecting) {
          if (sTriggers.top && !sTriggers.bottom) { topSection = s; }
          if (sTriggers.bottom && !sTriggers.top) { bottomSection = s; }
        }
      }
    }
  }
  function getSectionHeight(id, m = 1) {
    let tHeight = 0;
    const target = document.getElementById(id);
    if (target) {
      const bounds = target.getBoundingClientRect();
      tHeight = bounds.height;
    }
    return tHeight * m;
  }

  function addAnimations() {
    const intro = document.getElementById('intro');
    const iBounds = intro.getBoundingClientRect();
    const auto = (iBounds.y < -670) ? true : false;
    animations = true;

    const introH = getSectionHeight('intro');
    const collectionH = getSectionHeight('collections');
    const artistH = getSectionHeight('theartist');
    const exhibitiionsH = getSectionHeight('exhibitions');
    const pressH = getSectionHeight('press');
    const merchH = getSectionHeight('merchandise');
    const totalH = introH + collectionH + artistH + exhibitiionsH + pressH + merchH;
    /*
    headerAnimation = anime({
      targets: headerContainer,
      translateY: ['50vh', '0vh'],
      easing: 'easeInOutSine',
      autoplay: auto
    });
    logoAnimation = anime({
      targets: logoContainer,
      height: ['200px','48px'],
      translateY: ['-100px', '0px'],
      easing: 'easeInOutSine',
      autoplay: auto
    });
    */
    city0Animation = anime({
      targets: city0,
      translateX: ['-66%', '0%'],
      easing: 'easeInOutSine',
      autoplay: false
    });
    treeAnimation = anime({
      targets: trees,
      translateX: ['-66%', '0%'],
      easing: 'easeInOutSine',
      autoplay: false
    });

    cityAnimation = anime.timeline({
      easing: 'linear',
      duration: totalH,
      autoplay: false,
      loop: false
    });

    addTransitions('intro');
    addTransitions('collections', introH);
    addTransitions('theartist', introH + collectionH);
    addTransitions('exhibitions', introH + collectionH + artistH);
    addTransitions('press', introH + collectionH + artistH + exhibitiionsH);
  }

  function testVisibility() {
    let response = false;
    if (!minimumAspect) {
      response = (h >= screenWidthB) ? response : false;
    }
    response = (minimumHeight && minimumWidth) ? true : response;
    return response;
  }

  function sectionScrollPercentage(id) {
    const element = document.getElementById(id);
    if (!element) {
      console.error('Element not found');
      return null;
    }
    // Get the bounding rectangle of the element
    const rect = element.getBoundingClientRect();

    // Get the height of the viewport
    const viewportHeight = window.innerHeight;

    // Calculate the percentage
    // The top of the element is 0% when it's at the bottom of the viewport (rect.top = viewportHeight)
    // and 100% when it's at the top of the viewport (rect.top = 0)
    const percentage = 1 - rect.top / viewportHeight;

    // Clamping the percentage between 0% and 100%
    return Math.max(0, Math.min(1, percentage));
  }

  function getScrollPercentage() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const scrolled = scrollTop / (scrollHeight - clientHeight);
    return Math.min(1, Math.max(0, scrolled));
  }

  function lowestBorderYPoint() {
    const btmBorder = document.querySelector('[data-gsp-border="bottom"]');
    const bounds = btmBorder.getBoundingClientRect();
    borderHeight = bounds.height;

    const poly = btmBorder.querySelector('.border-b');
    const polyPoints = poly.getAttribute('points');
    const pointPairs = polyPoints.split(' ');
    // Extract all Y values
    const yValues = pointPairs.map(pair => {
      const parts = pair.split(',');
      return parseFloat(parts[1]);
    });
    
    // Find the greatest Y value
    const maxY = Math.max(...yValues);

    // Filter out non-positive values and find the minimum among the rest
    const minYAboveZero = Math.min(...yValues.filter(y => y > 0));

    // Calculate the difference
    const difference = maxY - minYAboveZero;
    return difference;
  }

  function resizeWindow(e) {
    w = window.innerWidth;
    h = window.innerHeight;
    minimumWidth = (w >= minDocWidth) ? true : false;
    minimumHeight = (h >= minDocHeight) ? true : false;
    minimumAspect = (h/w > minAspect) ? true : false;
    visibility = testVisibility();
    const resizeEvent = new CustomEvent('cityresize', { detail: { enable: visibility, }});
    document.dispatchEvent(resizeEvent);
  }
  function scrollCallback(e) {
    if (animations && activeSection) {
      const introSectionHeight = getSectionHeight('intro');

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollPerc = getScrollPercentage();
      const id = activeSection.getAttribute('id');
      const bid = (bottomSection) ? bottomSection.getAttribute('id') : false;
      const bounds = activeSection.getBoundingClientRect();
      const tMark = Math.abs(bounds.top - 0);
      const bMark = Math.abs(bounds.bottom - 0);
      const tPerc = (tMark/bounds.height > 1)? 1 : tMark/bounds.height;
      const bPerc = (bMark/bounds.height > 1)? 1 : bMark/bounds.height;

      let triggerPointStart,triggerPointEnd,triggerPerc,markVal,event;

      switch (id) {
        case 'intro':
          triggerPointStart = 0;
          triggerPointEnd = .3;
          markVal = triggerPointEnd - (triggerPointEnd - tPerc);
          triggerPerc = (markVal/triggerPointEnd > 1) ? 1 : markVal/triggerPointEnd;


          if (tPerc >= 0 && tPerc <= .33) {    
            if (triggerPerc <= 1) {
              //headerAnimation.seek(triggerPerc * headerAnimation.duration);
              //logoAnimation.seek(triggerPerc * headerAnimation.duration);
            }
          }
        break;
        default:
        break;
      }

      cityAnimation.seek(scrollTop);
      if (!cityActive && scrollTop >= introSectionHeight*.3) {
        cityActive = true;
        event = new CustomEvent('showflowers');
        document.dispatchEvent(event);
        event = new CustomEvent('cityintro');
        document.dispatchEvent(event);
      }

      if (cityActive && scrollTop < introSectionHeight*.3) {
        cityActive = false;
        event = new CustomEvent('cityoutro');
        //document.dispatchEvent(event);
        event = new CustomEvent('hideflowers');
        document.dispatchEvent(event);
        //event = new CustomEvent('animateborders', {detail: { border: 'both', direction: 'out', animate: 750 }});
        //document.dispatchEvent(event);
      }

      if (bottomSection && bid !== 'intro') {
        
        const sPerc = sectionScrollPercentage(bid);
      }
      city0Animation.seek(scrollPerc);
      treeAnimation.seek(scrollPerc);

      //city0Animation.seek(scrollPerc * headerAnimation.duration);
      //treeAnimation.seek(scrollPerc * headerAnimation.duration);
    }
  }

  apos.util.widgetPlayers['gspCity'] = {
    selector: '[data-gsp-city-active]',
    player: function(el) {
      document.addEventListener('cityresize', resizeMe);
      
      if (visibility) { 
        enableMe();
      }

      function resizeMe(e) {
        const enableDisplay = e.detail.enable;
        if (enableDisplay) { enableMe(); } else { disableMe(); }
      }

      function enableMe() {
        el.setAttribute('data-gsp-city-active', true);
      }
      function disableMe() {
        el.setAttribute('data-gsp-city-active', false);
      }
    }
  },
  apos.util.widgetPlayers['gspCityBackdrop'] = {
    selector: '[data-gspcity-0]',
    player: function (el) {
      const backgroundA = el.querySelector('[data-gspcity-bga');
      const backgroundB = el.querySelector('[data-gspcity-bgb');
      const backgrounds = el.querySelectorAll('.city-layer');
      document.addEventListener('cityresize', resizeMe);

      if (visibility) { enableMe(); }

      function enableMe() {
        document.addEventListener('cityintro', showBuildings);
        document.addEventListener('cityoutro', hideBuildings);
        if (ready && visibility) {
          showBuildings(null);
        }
      }
      function disableMe() {
        hideBuildings(null);
        document.removeEventListener('cityintro', showBuildings);
        document.removeEventListener('cityoutro', hideBuildings);
      }
      function resizeMe(e) {
        const enableDisplay = e.detail.enable;
        if (enableDisplay) { enableMe(); } else { disableMe(); }
      }

      function showBuildings(e) {
        backgrounds.forEach(background => {
          background.setAttribute('data-opacity', 1);
        });
      }
      function hideBuildings(e) {
        backgrounds.forEach(background => {
          background.setAttribute('data-opacity', 0);
        });
      }
    }
  },
  apos.util.widgetPlayers['gspCityFront'] = {
    selector: '[data-gspcity-front]',
    player: function (el) {
      document.addEventListener('cityresize', resizeMe);
      if (visibility) { enableMe(); }

      function enableMe() {
        document.addEventListener('cityintro', showBuildings);
        document.addEventListener('cityoutro', hideBuildings);
        if (ready && visibility) {
          showBuildings(null);
        }
      }
      function disableMe() {
        hideBuildings(null);
        document.removeEventListener('cityintro', showBuildings);
        document.removeEventListener('cityoutro', hideBuildings);
      }
      function resizeMe(e) {
        const enableDisplay = e.detail.enable;
        if (enableDisplay) { enableMe(); } else { disableMe(); }
      }

      function showBuildings(e) {
        el.classList.add('visible');
        const bounds = el.getBoundingClientRect();
        const btm = btmY - (bounds.height/2);
        /*
        anime({
          targets: el,
          bottom: btm,
          duration: 1000,
          easing: 'easeInOutSine'
        });
      */
      }
      function hideBuildings(e) {
        el.classList.remove('visible');
        const bounds = el.getBoundingClientRect();
        const btm = - bounds.height;
        /*
        anime({
          targets: el,
          bottom: btm,
          duration: 500,
          easing: 'easeInOutSine'
        });
        */
      }
    }
  },
  apos.util.widgetPlayers['gspCityClouds'] = {
    selector: '[data-gspcity-clouds]',
    player: function (el) {
      let cloudAnimation = [];
      const cloudsPerLat = el.getAttribute('data-max-clouds-per-lat');
      const totalLat = el.getAttribute('data-total-lat');
      const cloudPath = apos.util.assetUrl('/modules/gsp-cityscape/svg/cloud.svg');
      const cloudLines = [];

      document.addEventListener('cityresize', resizeMe);
      if (visibility) { enableMe(); }

      function enableMe() {
        document.addEventListener('cityintro', showClouds);
        document.addEventListener('cityoutro', hideClouds);
        if (ready && visibility) {
          showClouds(null);
        }
      }
      function disableMe() {
        hideClouds(null);
        document.removeEventListener('cityintro', showClouds);
        document.removeEventListener('cityoutro', hideClouds);
      }
      function resizeMe(e) {
        const enableDisplay = e.detail.enable;
        if (enableDisplay) { 
          enableMe();
          adjustCloudPositions(e);
        } else { 
          disableMe(); }
      }

      function adjustCloudPositions(e) {
        if (cloudLines.length) {
          cloudAnimation.forEach(cloudObj => {
            cloudObj.animation.pause();
            cloudObj.animation.remove(cloudObj.target);
          });
          cloudAnimation = [];
          generateClouds();
          animateClouds();
        }
      }

      function animateClouds(){
        if (!cloudAnimation.length) {
          const cloudSvgs = el.querySelectorAll('svg');
          const totalcs = cloudSvgs.length;
          const durationInc = 10000;
          //const delayInc = 500;
          let cDuration = 120000 + (durationInc * totalcs);
          //let cDelay = 0;
          cloudSvgs.forEach(cloudSet =>{
            cloudAnimation.push(
              {
                animation: anime({
                  targets: cloudSet,
                  left: [-w, w],
                  loop: true,
                  duration: cDuration,
                  delay: 0,
                  easing: 'linear'
                }),
                target: cloudSet,
              }
            );

            cDuration -= durationInc;
            //cDelay += delayInc;
          });
        } else {
          cloudAnimation.forEach(cloudObj => {
            cloudObj.animation.play();
          });
        }
      }

      function showClouds(e) {
        el.setAttribute('data-opacity', 1);
        el.classList.add('visible');

        if (!cloudLines.length) {
          generateClouds();
        }
        animateClouds();
      }
      function hideClouds(e) {
        if (cloudLines.length) {
          el.setAttribute('data-opacity', 0);
          el.classList.remove('visible');
          if (cloudAnimation.length) {
            cloudAnimation.forEach(cloudObj => {
              cloudObj.animation.pause();
            });
          }
        }
      }

      function generateClouds() {
        const create = (cloudLines.length != totalLat) ? true : false;
        const cloudMaxW = w*.11;
        const cloudMaxH = h*.11;
        const cmwinc = cloudMaxW/totalLat;
        const cmhinc = cloudMaxH/totalLat;
        var cw = cmwinc;
        var ch = cmhinc;
        var ypn = 0;
        var ypp = 0;
        var yinc = h*.05;
        for (var l=0; l<totalLat; l++) {
          const oe = (l%2) ? 'even' : 'odd';
          ypn = (oe === 'odd')? ypn - yinc : ypn;
          ypp = (oe === 'even')? ypp + yinc : ypp;
          const yp = (oe === 'odd') ? ypn : ypp;
          const multiplier = (l+1) * Math.random();
          const latSvg = (create) ? new SVG().addTo(el).size(w, 1).move(0, yp).addClass('cloud-line cloud-' + oe) : cloudLines[l];
          latSvg.clear();
          let clouds = [];
            
          const totalClouds = Math.floor(Math.random() * (cloudsPerLat - 1) + 1);
          const cloudDiv = w/totalClouds;
          var xp = w * Math.random(0, w-cw);
          for (var c=0; c<totalClouds; c++) {
            const xmin = cloudDiv * c;
            const xmax = xmin + cloudDiv;
            const xp = Math.random() * (xmax - xmin) + xmin;
            clouds[c] = latSvg.use('cloud', cloudPath).size(cw, ch).center(xp, yp).addClass('cloud foreground horizon-' + l);
          }

          cw += cmwinc;
          ch += cmhinc;
          cloudLines[l] = latSvg;
        }
      }
    }
  },
  apos.util.widgetPlayers['gspCityTrees'] = {
    selector: '[data-gspcity-trees]',
    player: function (el) {
      document.addEventListener('cityresize', resizeMe);
      if (visibility) { enableMe(); }

      function enableMe() {
        document.addEventListener('cityintro', showTrees);
        document.addEventListener('cityoutro', hideTrees);
        if (ready && visibility) {
          showTrees(null);
        }
      }
      function disableMe() {
        hideTrees(null);
        document.removeEventListener('cityintro', showTrees);
        document.removeEventListener('cityoutro', hideTrees);
      }
      function resizeMe(e) {
        const enableDisplay = e.detail.enable;
        if (enableDisplay) { enableMe(); } else { disableMe(); }
      }

      function showTrees(e) {
        el.classList.add('visible');
      }
      function hideTrees(e) {
        el.classList.remove('visible');
      }
    }
  },
  apos.util.widgetPlayers['gspCityBuildings'] = {
    selector: '[data-gspcity-buildings]',
    player: function (el) {
      const cityId = el.getAttribute('data-building-layer');
      const filename = 'city-' + cityId;
      document.addEventListener('cityresize', resizeMe);
      if (visibility) { enableMe(); }

      function enableMe() {
        document.addEventListener('cityintro', showMe);
        document.addEventListener('cityoutro', hideMe);
        if (ready && visibility) {
          showMe(null);
        }
      }
      function disableMe() {
        hideMe(null);
        document.removeEventListener('cityintro', showMe);
        document.removeEventListener('cityoutro', hideMe);
      }
      function resizeMe(e) {
        const enableDisplay = e.detail.enable;
        if (enableDisplay) { enableMe(); } else { disableMe(); }
      }

      function showMe(e) {
        el.classList.add('visible');
      }
      function hideMe(e) {
        el.classList.remove('visible');
      }


    }
  },
  apos.util.widgetPlayers['gspCityBridges'] = {
    selector: '[data-gspcity-bridges]',
    player: function (el) {
      const bridgePath = apos.util.assetUrl('/modules/gsp-cityscape/svg/bridge-left.svg');
      const svgObj = new SVG().addTo(el).size(w, h*.25);
      var bridge = svgObj.use('bridge', bridgePath).addClass('bridge');
      bridge.move(250,520);
      bridge.transform({
        scale: .25,
        rotate: -2,
      });
    }
  },
  apos.util.widgetPlayers['gspCityStatue'] = {
    selector: '[data-gspcity-statue]',
    player: function (el) {
      document.addEventListener('cityresize', resizeMe);
      if (visibility) { enableMe(); }

      function enableMe() {
        document.addEventListener('cityintro', showMe);
        document.addEventListener('cityoutro', hideMe);
        if (ready && visibility) {
          showMe(null);
        }
      }
      function disableMe() {
        hideMe(null);
        document.removeEventListener('cityintro', showMe);
        document.removeEventListener('cityoutro', hideMe);
      }
      function resizeMe(e) {
        const enableDisplay = e.detail.enable;
        if (enableDisplay) { enableMe(); } else { disableMe(); }
      }

      function showMe(e) {
        el.classList.add('visible');
      }
      function hideMe(e) {
        el.classList.remove('visible');
      }
    }
  }
}