const anime = require('animejs').default;
const SVG = require('@svgdotjs/svg.js').SVG;

const screenWidthA = 500;
const screenWidthB = 1024;
const screenWidthC = 1280;

//import gspBackgroundPlayer from './lib/gspBackground.js';

export default () => {
  apos.util.widgetPlayers['gspHeader'] = {
    selector: '[data-gsp-header]',
    player: function (el) {
      // set constants
      const test = true;

      let w = window.innerWidth;
      let h = window.innerHeight;

      const main = document.querySelector('.gsp-page');
      const container = el.querySelector('.container');
      const logo = el.querySelector('.gsp-logo');
      const hamburger = el.querySelector('.hamburger');
      const navContainer = el.querySelector('.navigation');
      const navigation = el.querySelector('nav');
      const navItems = el.querySelectorAll('.nav-item');
      const introOutEvent = new CustomEvent('gspintroout', { detail: { complete: true, }});
      const introInEvent = new CustomEvent('gspintroin', { detail: { complete: true, }});
      const navBg = el.querySelector('.mobile-nav-bg');

      let mBounds = main.getBoundingClientRect();
      //let activeLogo = logo.getAttribute('data-logo');
      let state = 'top';
      let display = 'none';
      let processedState = null;
      let scrollPos = window.scrollY;
      let totalScrollPerc = scrollPos/mBounds.height;
      let vpScrollPerc = scrollPos/h;
      let navTimer = null;
      let activeNavItem = 0;
      let logoTimer = false;
      let headerBgBack, headerBgFront, footerBgBack, footerBgFront, menuBg;
      let bgYBase = (display === 'desktop') ? 90 : 64;
      let bgYBaseAdd = (display === 'desktop') ? 24 : 16;
      let bgTranslateYStart = 50;
      let menuXPosOpen = 0;

      window.addEventListener('resize', resizeWindow);
      document.addEventListener('scroll', scrollEvents);

      hamburger.addEventListener('click', hamburgerClick);
      apos.util.onReady(initializeDisplay());

      function initializeDisplay() {
        if (w <= screenWidthA) {
          window.dispatchEvent(introOutEvent);
        }
        resetDisplay();
      }

      function adjustHeaderBg() {
        //svgObj.size(w,64);
        bgYBase = (display === 'desktop') ? 90 : 64;
        bgYBaseAdd = (display === 'desktop') ? 24 : 16;
        const m = (display === 'desktop') ? 2 : 1.5;

        // reposition menu bg poly
        var size = (display === 'tablet') ? 250 : w;
        menuXPosOpen = w - size;
      }
      
      function enableDesktopNavigation() {
        navItems.forEach(item => {
          item.setAttribute('data-opacity', 1);
        });
      }
      function disableDesktopNavigation() {
        navItems.forEach(item => {
          item.setAttribute('data-opacity', 0);
        });
      }
      function hamburgerClick(e) {
        const isOn = hamburger.classList.contains('active');
        //navContainer.classList.toggle('active');
        //hamburger.classList.toggle('active');

        if (isOn) {
          hideMobileNav();
        } else {
          showMobileNav();
        }
      }
      function showMobileNav() {
        navContainer.classList.add('active');
        hamburger.classList.add('active');
        navigation.classList.add('visible');
        navBg.classList.add('visible');
        navItems.forEach(item => {
          item.setAttribute('data-opacity', 1);
          item.addEventListener('click', hideMobileNav);
        });
      }
      function hideMobileNav() {
        navContainer.classList.remove('active');
        hamburger.classList.remove('active');
        navigation.classList.remove('visible');
        navBg.classList.remove('visible');
        navItems.forEach(item => {
          item.setAttribute('data-opacity', 0);
          item.removeEventListener('click', hideMobileNav);
        });
      }
      function scrollEvents(e) {
        scrollPos = window.scrollY;
        resetDisplay();
      }

      function resizeWindow(e) {
        if (window.innerWidth !== w) {
          resetDisplay();
        }

        w = window.innerWidth;
        h = window.innerHeight;
      }

      function resetDisplay() {
        const classActive = el.classList.contains(display);
        const previousClass = display;

        //debug(w + '-' + h);

        display = (w <= screenWidthA) ? 'mobile' : display;
        display = (w > screenWidthA) ? 'tablet' : display;
        display = (w >= screenWidthB) ? 'desktop' : display;

        resetScrollPercentages();
        
        if (classActive && previousClass !== display) {
          el.classList.remove(previousClass);
          el.classList.add(display);

          logo.classList.remove(previousClass);
          logo.classList.add(display);
        }

        if (!classActive) {
          el.classList.add(display);
          logo.classList.add(display);
        }

        adjustHeader();
      }

      function adjustHeader() {
        const testState = `${display}-${state}`;
        var logoType = 'text';
        container.setAttribute('data-display', `${display}-${state}`);
        navContainer.setAttribute('data-navigation', `${display}-${state}`);
        adjustHeaderBg();

        switch(testState) {
          case 'mobile-init':
          case 'tablet-init':
          case 'desktop-init':
            if (processedState !== testState) {
              processedState = testState;
            }
            break;
          case 'mobile-top':
          case 'tablet-top':
          case 'desktop-top':
            if (processedState !== testState) {
              processedState = testState;
              enableDesktopNavigation();
              if (testState == 'desktop-top') {  }
            }
            break;
          case 'mobile-scrolling':
            logoType = 'none';
          case 'tablet-scrolling':
          case 'desktop-scrolling':
            if (processedState !== testState) {
              processedState = testState;
              disableDesktopNavigation();
              if (testState == 'desktop-scrolling') {  }
            }
            break;
          default:
            break;
        }
      }

      function resetScrollPercentages() {
        mBounds = main.getBoundingClientRect();
        totalScrollPerc = scrollPos/mBounds.height;
        vpScrollPerc = scrollPos/h;

        //if (state !== 'init' && vpScrollPerc < .1) { state = 'top'; }
        //if (state !== 'top' && vpScrollPerc > .9) { state = 'top'; }
        //if (state !== 'scrolling' && vpScrollPerc > .5 && vpScrollPerc < .9) { state = 'scrolling'; }
        //if (display === 'mobile' || display === 'tablet') { state = 'top'}
      }
    }
  }
};