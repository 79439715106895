(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();import index_0App from "/app/node_modules/apostrophe/modules/@apostrophecms/util/ui/src/index.js";


import index_1App from "/app/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/src/index.js";


import index_2App from "/app/node_modules/apostrophe/modules/@apostrophecms/video-widget/ui/src/index.js";


import index_3App from "/app/modules/asset/ui/src/index.js";


import index_4App from "/app/modules/gsp-background/ui/src/index.js";


import index_5App from "/app/modules/gsp-collections/ui/src/index.js";


import index_6App from "/app/modules/gsp-header/ui/src/index.js";


import index_7App from "/app/modules/gsp-footer/ui/src/index.js";


import index_8App from "/app/modules/gsp-flowers/ui/src/index.js";


import index_9App from "/app/modules/gsp-cityscape/ui/src/index.js";


import index_10App from "/app/modules/gsp-artist/ui/src/index.js";


import index_11App from "/app/modules/gsp-merch/ui/src/index.js";


import index_12App from "/app/modules/gsp-scroller/ui/src/index.js";


import index_13App from "/app/modules/gsp-press/ui/src/index.js";



import index_0Stylesheet from "/app/node_modules/apostrophe/modules/@apostrophecms/image-widget/ui/src/index.scss";


import index_1Stylesheet from "/app/modules/asset/ui/src/index.scss";


import index_2Stylesheet from "/app/modules/gsp-background/ui/src/index.scss";


import index_3Stylesheet from "/app/modules/gsp-collections/ui/src/index.scss";


import index_4Stylesheet from "/app/modules/gsp-header/ui/src/index.scss";


import index_5Stylesheet from "/app/modules/gsp-flowers/ui/src/index.scss";


import index_6Stylesheet from "/app/modules/gsp-cityscape/ui/src/index.scss";


import index_7Stylesheet from "/app/modules/gsp-artist/ui/src/index.scss";


import index_8Stylesheet from "/app/modules/gsp-shows/ui/src/index.scss";


import index_9Stylesheet from "/app/modules/gsp-merch/ui/src/index.scss";


import index_10Stylesheet from "/app/modules/gsp-press/ui/src/index.scss";index_0App();
index_1App();
index_2App();
index_3App();
index_4App();
index_5App();
index_6App();
index_7App();
index_8App();
index_9App();
index_10App();
index_11App();
index_12App();
index_13App();