const anime = require('animejs').default;

export default () => {
  document.addEventListener('fadein', fadeElementIn);
  document.addEventListener('fadeout', fadeElementOut);

  //alert(document.body.style.animate);

  if (CSS.supports('animation')) {
    console.log('i work!');
  }

  //screen.orientation.lock('portrait');

  function fadeElementIn(e) {
    const elements = document.querySelectorAll('[data-opacity="0"]');
    const target = e.details.target;
  }
  function fadeElementOut(e) {
    const elements = document.querySelectorAll('[data-opacity="1"]');
    const target = e.details.target;
  }
};
